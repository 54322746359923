import { useEffect, useState } from "react";
import { ComponenteEmpresa } from "../../componentes/empresa";
import { Busca, Url } from "../../controller/function";
import InputMask from "react-input-mask";
export function PageFideldiade() {
  const [Empresa, setEmpresa] = useState([]);
  const [Categoria, setCategoria] = useState([]);

  const [Cliente, setCliente] = useState({
    celular: "",
    nome: "",
    cpf: "",
    nascimento: "",
    rua: "",
    ruaa: "",
    bairro: "",
    cidade: "",
    uf: "",
    complemento: "",
    numero: "",
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    let Client = JSON.parse(Busca("client"));

    if (Client) {
      setCliente(Client);
    } else {
      if (window.location.href.includes("/fidelidade")) {
        // Remove "/fidelidade" da URL e redireciona
        const novaUrl = window.location.href.replace("/fidelidade", "");
        window.location.href = novaUrl;
      }
    }
  }, []);

  return (
    <>
      <ComponenteEmpresa
        setempresa={setEmpresa}
        setcategoria={setCategoria}
        display={"none"}
      />

      {Empresa.length !== 0 ? (
        <>
          <div className="bg-top sobre"></div>

          <header className="width-fix mt-3">
            <div className="card">
              <div className="d-flex">
                <a href={Url()} className="container-voltar">
                  <i className="fas fa-arrow-left"></i>
                </a>
                <div className="infos text-center">
                  <h1 className="mb-0">
                    <b>Programa de Fidelidade</b>
                  </h1>
                </div>
              </div>
            </div>
          </header>

          <header className="width-fix mt-2">
            <div className="card">
              <div className="d-flex">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  {" "}
                  <div
                    className="container-img"
                    style={{
                      backgroundImage: `url("/gift.png")`,
                    }}
                  ></div>
                </div>
                <div className="infos">
                  <h1 style={{ fontSize: "0.85rem" }}>
                    Seus Pontos: <b>100</b>
                  </h1>
                  {/* <p style={{ fontSize: "0.75rem" }}>
                    A cada R$ 1,00 em compras você ganha 1 ponto que pode ser
                    trocado por prêmios e descontos exclusivos.
                  </p> */}
                  <a
                    href={Url() + "/fidelidade"}
                    className="link"
                    style={{ fontSize: "0.75rem" }}
                  >
                    Clique aqui e leia o regulamento
                  </a>
                </div>
              </div>
            </div>
          </header>
          {Cliente.celular ? (
            <>
              <section className="opcionais width-fix mt-3 pb-5">
                <div className="container-group mb-5">
                  <div className="container-group mb-2">
                    <p className="title-categoria mb-0">
                      <b>Celular</b>
                    </p>

                    <InputMask
                      mask="(99) 99999-9999"
                      maskChar={null}
                      placeholder="(00) 00000-0000"
                      className="form-control mt-2"
                      // onChange={(e) => SetaCelular(e.target.value)}
                      value={Cliente.celular}
                    >
                      {(inputProps) => <input {...inputProps} />}
                    </InputMask>
                  </div>

                  <div className="container-group mb-2">
                    <p className="title-categoria mb-0">
                      <b>Nome</b>
                    </p>

                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="* Informe o nome e sobrenome"
                      value={Cliente.nome}
                    />
                  </div>
                  <div className="container-group mb-2">
                    <p className="title-categoria mb-2">
                      <b>CPF</b>
                    </p>

                    <InputMask
                      mask="999.999.999-99"
                      maskChar={null}
                      placeholder="999.999.999-99"
                      className="form-control mt-2"
                      value={Cliente.cpf}
                    >
                      {(inputProps) => <input {...inputProps} />}
                    </InputMask>
                  </div>

                  <div className="container-group mb-3">
                    <p className="title-categoria mb-2">
                      <b>Nascimento</b>
                    </p>

                    <InputMask
                      mask="99/99/9999"
                      maskChar={null}
                      placeholder="99/99/9999"
                      className="form-control mt-2"
                      // onChange={(e) => SetDadosCliente("nascimento", e.target.value)}
                      value={Cliente.nascimento}
                    >
                      {(inputProps) => <input {...inputProps} />}
                    </InputMask>
                  </div>
                </div>
              </section>

              <header className="width-fix mt-2">
                <div className="card">
                  <div className="d-flex">
                    <h1>Resgates</h1>
                    <h1>Recebimento</h1>
                  </div>
                </div>
              </header>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
}
