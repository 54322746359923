import { useParams } from "react-router-dom";
import {
  APIGOOPEDIR,
  DecodeCodigo,
  FormatoBR,
  Notificacao,
  PrimeiraLetraMaiuscula,
  StatusPedidoDetalhe,
  Url,
  converterData,
} from "../../controller/function";
import { ComponenteEmpresa } from "../../componentes/empresa";
import { useEffect, useState } from "react";
import { DetalheCard } from "./componente/detalheCard";
import { ProdutoCard } from "./componente/produtoCard";
import { DetalheStatus } from "./componente/detalheStatus";
import { ComponentLoading } from "../../componentes/loading";

export function PageDetalhePedido({}) {
  const { codigo } = useParams();

  const [Empresa, setEmpresa] = useState(null);
  const [Categoria, setCategoria] = useState([]);
  const [DadosPedido, setDadosPedido] = useState({});
  const [Pedido, setPedido] = useState(null);
  const [Produto, setProduto] = useState([]);
  const [StatusPedido, setStatusPedido] = useState([]);
  const [AguardandoPagamento, setAguardandoPagamento] = useState(false);

  function CodigoPedido() {
    return DecodeCodigo(codigo);
  }

  function bufferToBase64(bufferObject) {
    // if (bufferObject.type !== "Buffer" || !Array.isArray(bufferObject.data)) {
    //   throw new Error("Objeto de buffer inválido");
    // }
    // // Converter o array de bytes em uma string base64
    // const byteArray = new Uint8Array(bufferObject.data);
    // let binaryString = "";
    // for (let i = 0; i < byteArray.length; i++) {
    //   binaryString += String.fromCharCode(byteArray[i]);
    // }
    // const base64String = btoa(binaryString);
    return "data:image/png;base64," + bufferObject;
  }

  function BuscaDadosPedido() {
    APIGOOPEDIR.get(`api/pedido/${CodigoPedido()}`).then(function (response) {
      let data = response.data;

      setDadosPedido(data);
      setPedido(data.pedido);
      setProduto(data.produto);
      setAguardandoPagamento(
        data.pedido.transacao_id_mp == "" && data.pedido.id_mp != ""
      );

      let test = data.status;
      test.sort((a, b) => {
        let dateA = new Date(a.datahora);
        let dateB = new Date(b.datahora);
        return dateB - dateA;
      });
      setStatusPedido(test);
    });
  }

  useEffect(() => {
    BuscaDadosPedido();
  }, []);

  useEffect(() => {
    if (StatusPedido.length > 0) {
      const checkStatus = async () => {
        let value = await StatusPedidoDetalhe(CodigoPedido());

        if (StatusPedido.length != value.status.length) {
          Notificacao(value.status[value.status.length - 1].status);
          BuscaDadosPedido();
        }
      };

      const intervalId = setInterval(checkStatus, 15000);

      return () => clearInterval(intervalId);
    }
  }, [StatusPedido]);

  useEffect(() => {}, []);

  function Retorno() {
    window.location.href = Url() + "/pedido";
  }

  return (
    <>
      <ComponenteEmpresa
        setempresa={setEmpresa}
        setcategoria={setCategoria}
        display={"none"}
      />

      {Pedido ? (
        <>
          <header className="width-fix mt-3">
            <div className="card">
              <div className="d-flex">
                <div className="container-voltar" onClick={Retorno}>
                  <i className="fas fa-arrow-left"></i>
                </div>
                <div className="infos text-center">
                  <h1 className="mb-0">
                    <b>Pedido</b>
                  </h1>
                </div>
              </div>
            </div>
          </header>
          <section className="carrinho width-fix mt-2">
            {AguardandoPagamento ? (
              <>
                <div className="card mb-2">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",

                      justifyContent: "center",
                    }}
                  >
                    <p style={{ fontSize: "0.99rem" }}>
                      <b>Qrcod Para Pagamento</b>
                    </p>
                    <div
                      style={{
                        display: "flex",

                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ height: "128px", width: "128px" }}
                        src={
                          Pedido?.qrcod_pix
                            ? bufferToBase64(Pedido?.base64)
                            : null
                        }
                        alt=""
                      />
                    </div>
                    <p style={{ fontSize: "0.79rem" }}>{Pedido?.qrcod_pix}</p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",

                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="../../../img/logo-mp.png"
                      alt="mercado pago"
                      style={{
                        width: "140px",
                        height: "60px",
                        marginBottom: "10px",
                      }}
                    />
                  </div>

                  <a className="btn btn-yellow btn-sm">Pix Copia e Cola</a>
                </div>
              </>
            ) : (
              <>
                {StatusPedido.map((status, index) => (
                  <>
                    {status.pontos == 0 ? (
                      <DetalheStatus detalhe={status} ativo={index == 0} />
                    ) : null}
                  </>
                ))}
              </>
            )}

            {/* <div class="card card-status-pedido mt-2 active">
            <div class="img-icon-details">
              <i class="fas fa-clock"></i>
            </div>
            <div class="infos">
              <p class="name mb-1">
                <b>Pedido enviado!</b>
              </p>
              <span class="text mb-0">Aguardando a confirmação da loja</span>
            </div>
          </div>
        </div> */}

            {Produto.map((produto, index) => (
              <>
                <ProdutoCard produto={produto} />
              </>
            ))}

            {/* <DetalheStatus detalhe={''} /> */}
            {/* 
        <div class="card card-status-pedido mt-2 cancelado">
          <div class="img-icon-details">
            <i class="fas fa-times"></i>
          </div>
          <div class="infos">
            <p class="name mb-1">
              <b>Pedido cancelado!</b>
            </p>
            <span class="text mb-0">
              O restaurante cancelou o seu pedido. Entre em contato para mais
              informações.
            </span>
          </div>
        </div> */}

            <div className="card mb-2 mt-2">
              {Pedido?.codigo_pedido > 0 ? (
                <>
                  <DetalheCard
                    Descricao="Pedido"
                    Valor={Pedido?.codigo_pedido}
                  />
                </>
              ) : (
                <DetalheCard
                  Descricao="Seu pedido ainda não foi confirmado"
                  Valor={""}
                />
              )}

              <DetalheCard
                Descricao="Data"
                Valor={converterData(Pedido?.data)}
              />
              {Pedido?.sub_total > 0 ? (
                <>
                  <DetalheCard
                    Descricao="Produtos"
                    Valor={FormatoBR(Pedido?.sub_total)}
                  />
                </>
              ) : null}

              {Pedido?.desconto > 0 ? (
                <>
                  <DetalheCard
                    Descricao="Desconto"
                    Valor={FormatoBR(Pedido?.desconto)}
                  />
                </>
              ) : null}

              {Pedido?.valor_taxa > 0 ? (
                <>
                  <DetalheCard
                    Descricao="Entrega"
                    Valor={FormatoBR(Pedido?.valor_taxa)}
                  />
                </>
              ) : null}

              {Pedido?.vl_taxa > 0 ? (
                <>
                  <DetalheCard
                    Descricao="Taxa de Serviço"
                    Valor={FormatoBR(Pedido?.vl_taxa)}
                  />
                </>
              ) : null}

              {Pedido?.total + Pedido?.vl_taxa > 0 ? (
                <>
                  {" "}
                  <DetalheCard
                    Descricao="Total"
                    Valor={FormatoBR(Pedido?.total + Pedido?.vl_taxa)}
                  />
                </>
              ) : null}
            </div>
          </section>
        </>
      ) : (
        <>
          {" "}
          <ComponentLoading />
        </>
      )}
    </>
  );
}
