import Skeleton from "react-loading-skeleton";
import {
  APIGOOPEDIR,
  Busca,
  FormatoBR,
  PrimeiraLetraMaiuscula,
  RetornaCategoria,
  RetornaProduto,
  Url,
  ValorProduto,
} from "../../controller/function";
import { useState, useEffect } from "react";
import { FaGift } from "react-icons/fa";

export function ComponenteProdutoPrincipal({ categoria, empresa, total }) {
  const [Produto, setProduto] = useState([]);

  useEffect(() => {
    async function fetchEmpresa() {
      let value = await RetornaProduto(categoria);

      if (value != null) {
        const updatedProdutos = await Promise.all(
          value.dados.map(async (produto) => {
            try {
              const result = await APIGOOPEDIR.get(
                `api/produto/valores/${produto.id}`
              );

              produto.pizza_qtd = result.data.dados[0].pizza_qtd;
              produto.pizza_max = result.data.dados[0].pizza_max + 10;
              produto.pizza_min = result.data.dados[0].pizza_min + 10;
              produto.pizza_tipo = result.data.dados[0].pizza_tipo;
            } catch (error) {
              console.error("Erro ao buscar valores do produto", error);
            }
            return produto;
          })
        );
        setProduto(updatedProdutos);
      }
    }
    fetchEmpresa();
  }, [categoria]);

  const idProduto = empresa.user_id + "&&-&&" + empresa.nome_empresa + "&&-&&";

  function Crip(valor) {
    valor = btoa(valor);
    return valor.slice(0, -2);
  }
  function AbrirProduto(codigo) {
    window.location.href = Url() + "/produto/" + codigo;
  }

  function LoadSkeleton() {
    const elementos = [];

    // Loop para criar elementos JSX
    for (let i = 0; i < total - 1; i++) {
      // Adiciona elementos JSX ao array
      elementos.push(
        <div key={i} className="card mb-2 item-cardapio">
          <div className="d-flex">
            <div
              className="container-img-produto"
              style={{ backgroundColor: "transparent" }}
            >
              <Skeleton
                containerClassName="ontainer-img-produto"
                height={"100%"}
                width={"100%"}
                className="skeleton-item"
                style={{ maxWidth: "650px" }}
              />
            </div>
            <div className="infos-produto">
              <Skeleton
                height={"20px"}
                width={"100%"}
                className="skeleton-item"
                style={{ maxWidth: "630px" }}
              />

              <Skeleton
                height={"20px"}
                width={"100%"}
                className="skeleton-item"
              />

              <Skeleton
                height={"20px"}
                width={"50vw"}
                className="skeleton-item"
                style={{ maxWidth: "620px" }}
              />
            </div>
          </div>
        </div>
      );
    }

    return <div>{elementos}</div>;
  }

  return (
    <>
      {Produto.length != 0 ? (
        <>
          {Produto.map((produto, index) => (
            <div
              className="card mb-2 item-cardapio"
              key={index}
              id={Crip(idProduto + produto.id + "&&-&&" + produto.nome_item)}
              onClick={() =>
                AbrirProduto(
                  Crip(idProduto + produto.id + "&&-&&" + produto.nome_item)
                )
              }
            >
              <div className="d-flex">
                <div
                  className="container-img-produto"
                  style={{
                    backgroundImage:
                      produto.img_ifood != ""
                        ? `url('${produto.img_ifood}')`
                        : `url('https://fotos.goopedir.com/fotos/${btoa(
                            produto.id
                          )}')`,
                    backgroundSize: "cover",
                  }}
                >
                  {produto.fidelidade_valor > 0 ? (
                    <>
                      <div className="gift-icon">
                        <FaGift />
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="infos-produto">
                  {index == 0 ? (
                    <p className="mais-venvida">
                      <b>Mais Pedido</b>
                    </p>
                  ) : null}
                  <p className="name">
                    <b>{PrimeiraLetraMaiuscula(produto.nome_item)}</b>
                  </p>
                  <p className="description">
                    {PrimeiraLetraMaiuscula(produto.descricao_item)}
                  </p>
                  <p className="price">
                    <b>{ValorProduto(produto)}</b>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <LoadSkeleton />
        </>
      )}
    </>
  );
}
