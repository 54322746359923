import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";

const Mapa = ({ Latitude, Longitude, Empresa }) => {
  // const position = [Latitude, Longitude]; // Coordenadas iniciais

  const [position, setPosition] = useState([
    Latitude ? Latitude : -28.675358,
    Longitude ? Longitude : -49.368551,
  ]);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleClick = (e) => {
    setSelectedLocation(e.latlng);
  };

  useEffect(() => {
    setPosition([Latitude, Longitude]);
  }, []);

  return (
    <div style={{ height: "280px" }}>
      {selectedLocation}
      <MapContainer
        center={position}
        zoom={13}
        style={{ width: "100%", height: "100%" }}
        onClick={handleClick}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">GooPedir</a>'
        />
        <Marker
          position={position}
          icon={
            new Icon({
              iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
              iconUrl: require("leaflet/dist/images/marker-icon.png"),
              shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
            })
          }
        >
          <Popup>
            {/* {Empresa} */}

            {Latitude != position[0] ? "Seu Endereço" : Empresa}

            {/* A pretty CSS3 popup. <br /> Easily customizable. */}
          </Popup>
        </Marker>

        {/* {selectedLocation && (
          <Marker position={selectedLocation}>
            <Popup>Localização selecionada</Popup>
          </Marker>
        )} */}
      </MapContainer>
    </div>
  );
};

export default Mapa;
