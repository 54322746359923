import { FaGift } from "react-icons/fa";
import {
  FormatoBR,
  PrimeiraLetraMaiuscula,
} from "../../../../controller/function";

export function ComponenteCarrinhoProduto({ product, remove, index }) {
  console.log(product);
  const Adicionais = (adicional) => {
    var filtered = adicional.adicional.selecionado.filter(function (item) {
      return item !== null && typeof item === "object";
    });

    filtered = filtered.filter(
      (item) => !(typeof item === "object" && Object.keys(item).length === 0)
    );

    return (
      <>
        {filtered.map((adicional, index) => (
          <>
            <div className="infos-produto" key={"adc" + index}>
              <p className="name-opcional mb-0">
                {adicional.qtd}x {PrimeiraLetraMaiuscula(adicional.name)}
              </p>
              {adicional.value > 0 ? (
                <p className="price-opcional mb-0">
                  + {FormatoBR(adicional.value)}
                </p>
              ) : null}
            </div>
          </>
        ))}
      </>
    );
  };

  const Pizza = (pizza, outros) => {
    return (
      <>
        <div className="infos-produto" key={"adc" + index}>
          <p className="name-opcional mb-0">
            {`1/${pizza.outros.pizza.quantidade} ${PrimeiraLetraMaiuscula(
              pizza.pizza.tipo
            )} ${PrimeiraLetraMaiuscula(pizza.pizza.nome)}`}
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="card mb-2 pr-0">
        <div className="container-detalhes">
          <div className="detalhes-produto">
            <div className="infos-produto">
              <p className="name">
                <b>
                  {product.quantidade}x{" "}
                  {PrimeiraLetraMaiuscula(product.produto.nome_item)}
                </b>
              </p>
              {product.total > 0 ? (
                <p className="price">
                  <b>{FormatoBR(product.total)}</b>
                </p>
              ) : null}
            </div>

            {product.pizza?.sabores.map((sabores, index) => (
              <>
                <Pizza pizza={sabores} outros={product} />
              </>
            ))}

            {product.adicional.map((adicional, index) => (
              <>
                <Adicionais adicional={adicional} />
              </>
            ))}

            {product.observacao.length > 0 ? (
              <div className="infos-produto">
                {" "}
                <p className="obs-opcional mb-0">- {product.observacao}</p>{" "}
              </div>
            ) : null}
          </div>

          <div className="detalhes-produto-edit">
            <i className="fas fa-trash" onClick={() => remove(index)}></i>
          </div>
        </div>
        {product.produto.fidelidade_valor > 0 ? (
          <div className="detalhes-produto ">
            <div className="card-opcionais mt-2">
              <>
                <div className="infos-produto-opcional">
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <FaGift />
                      <p className="name mb-0">
                        <b>{`Resgatar Com ${
                          product.produto.fidelidade_valor * product.quantidade
                        } Pontos`}</b>
                      </p>
                    </div>

                    <p>
                      <p className="title-categoria mb-0">
                        {/* {Empresa.msg_tempo_delivery} */}
                      </p>
                    </p>
                  </div>
                </div>
              </>

              <div
                className="checks"
                style={{ marginRight: "20px" }}
                // onClick={() => [
                //   setDelivery(true),
                //   FocoComponente("endereco"),
                // ]}
              >
                <label className="container-check">
                  <input type="checkbox" checked={false} />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
