import React, { useState, useRef, useEffect } from "react";
import { Busca } from "../../../controller/function";
import { PainelPedidos } from "../pedidos";
export function PainelInicio() {
  const [Empresa, setEmpresa] = useState({});

  useEffect(() => {
    // setEmpresa();
    // JSON.parse(Busca('painel_empresa')
    setEmpresa(JSON.parse(Busca("painel_empresa")));
  }, []);
  return (
    <>
      <section class="bg-menu">
        <div class="menu-left">
          <div class="logo">
            <img src="../img/logo.png" width="100" />
          </div>

          <div class="menus">
            <a href="./home.html" class="menu-item active">
              <i class="fas fa-home"></i> Início
            </a>

            <a href="./pedidos.html" class="menu-item">
              <i class="fas fa-utensils"></i> Pedidos
            </a>

            <a href="./cardapio.html" class="menu-item">
              <i class="fas fa-book-open"></i> Cardápio
            </a>

            <a href="./configuracoes.html" class="menu-item">
              <i class="fas fa-cog"></i> Configurações
            </a>

            <a href="#" class="menu-item">
              <i class="fas fa-building"></i> Empresa
            </a>

            <a href="#" class="menu-item">
              <i class="fas fa-chart-line"></i> Relatório
            </a>
          </div>
        </div>

        <div class="conteudo">
          <div class="menu-top">
            <div class="container">
              <div class="row">
                <div class="col-12 d-flex align-items-center mt-4">
                  <h1 class="title-page">
                    <b>
                      <i class="fas fa-home"></i>&nbsp; Seja bem-vindo(a)!
                    </b>
                  </h1>

                  <div class="container-right">
                    <div class="container-dados">
                      <p>{Empresa.nome_empresa}</p>
                      <span>{Empresa.email_empresa}</span>
                    </div>
                    <a href="#" class="btn btn-white btn-sm">
                      <i class="fas fa-sign-out-alt"></i>&nbsp; Sair
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="conteudo-inner">
            <PainelPedidos />
          </div>
        </div>
      </section>
    </>
  );
}
