import {
  APIGOOPEDIR,
  Busca,
  RetornaEmpresa,
  Url,
} from "../controller/function";

export async function EnviaClienteRedis(empresa) {
  let client = JSON.parse(Busca("client"));
  let Empresa = await RetornaEmpresa(empresa);

  APIGOOPEDIR.post(`api/recovery/cliente`, client, {
    headers: {
      celular: client.celular,
      nome: client.nome,
      user: Empresa.user_id,
      site: Url(),
    },
  });
}

export async function BuscaClienteRedis(empresa, celular) {
  let Empresa = await RetornaEmpresa(empresa);
  const response = await APIGOOPEDIR.get(`/api/recovery/cliente`, {
    headers: {
      celular: celular,
      user: Empresa.user_id,
      site: Url(),
    },
  });
  return response.data;
}

export async function BuscaOrderRedis(empresa, celular) {
  let Empresa = await RetornaEmpresa(empresa);
  const response = await APIGOOPEDIR.get(`/api/recovery/order`, {
    headers: {
      celular: celular,
      user: Empresa.user_id,
      site: Url(),
    },
  });
  return response.data;
}

export function removeRecoveryPath(url) {
  const recoveryRegex = /\/recovery.*?\/recovery/;
  return url.replace(recoveryRegex, "/recovery");
}
