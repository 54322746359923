import { FaGift } from "react-icons/fa";

export function CardFidelidade({ product }) {
  if (product.fidelidade_valor == 0) {
    return null;
  }

  return (
    <header className="width-fix mt-2">
      <div className="card">
        <div className="d-flex">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {" "}
            <div
              className="container-img"
              style={{
                backgroundImage: `url("/gift.png")`,
              }}
            ></div>
          </div>
          <div className="infos">
            <h1 style={{ fontSize: "0.8rem" }}>
              Resgate esse produto com{" "}
              <b>
                <span className="link">{product.fidelidade_valor}</span>
              </b>{" "}
              pontos.
            </h1>
            <h3 style={{ fontSize: "0.65rem" }}>
              Acumule pontos nas suas compras e troque por prêmios e descontos
              exclusivos!
            </h3>
          </div>
        </div>
      </div>
    </header>
  );
}
