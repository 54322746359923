import { Busca } from "../../controller/function";

export function ComponentLoading() {
  const setFavicon = (href) => {
    let link = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = href;
    } else {
      link = document.createElement("link");
      link.rel = "icon";
      link.href = href;
      document.head.appendChild(link);
    }
  };

  const setIcon = (href) => {
    let link = document.querySelector("link[rel='apple-touch-icon']");
    if (link) {
      link.href = href;
    } else {
      link = document.createElement("link");
      link.rel = "apple-touch-icon";
      link.href = href;
      document.head.appendChild(link);
    }
  };
  document.title = Busca("name");
  setFavicon(Busca("icon"));
  setIcon(Busca("icon"));

  return (
    <>
      <div className="center-div">
        <div class="loader-container">
          <div class="logo">
            <img src={Busca("icon") ? Busca("icon") : "/logo.png"} alt="Logo" />
          </div>
          <div class="spinner">
            <div class="dot dot1"></div>
            <div class="dot dot2"></div>
            <div class="dot dot3"></div>
            <div class="dot dot4"></div>
            <div class="dot dot5"></div>
            <div class="dot dot6"></div>
            <div class="dot dot7"></div>
            <div class="dot dot8"></div>
          </div>
        </div>
      </div>
    </>
  );
}
