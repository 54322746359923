import { useEffect, useState } from "react";
import { Grava, RetornaEmpresa } from "../../controller/function";
import { useParams } from "react-router-dom";
import {
  BuscaClienteRedis,
  BuscaOrderRedis,
  removeRecoveryPath,
} from "../../utils/util";

export function PageRecovery() {
  const { celular } = useParams();
  const { empresa } = useParams();

  const [Empresa, setEmpresa] = useState([]);
  const [Cliente, setCliente] = useState([]);
  const [Pedido, setPedido] = useState([]);

  useEffect(() => {
    async function fetchEmpresa() {
      let value = await RetornaEmpresa(empresa);

      setEmpresa(value);
    }
    fetchEmpresa();
  }, []);

  useEffect(() => {
    async function fetchCliente() {
      let value = await BuscaClienteRedis(empresa, celular);
      Grava("client", JSON.stringify(value));
      setCliente(value); // setEmpresa(value);
    }
    fetchCliente();
  }, [Empresa]);

  useEffect(() => {
    async function fetchOrder() {
      let value = await BuscaOrderRedis(empresa, celular);
      Grava("cart", JSON.stringify(value));

      setPedido(value); // setEmpresa(value);
    }
    fetchOrder();
  }, [Cliente]);

  useEffect(() => {
    // console.log(removeRecoveryPath(window.location.href));
  }, [Pedido]);

  return <></>;
}
