import { useState, useEffect, useRef } from "react";
import { ComponenteEmpresa } from "../../componentes/empresa";
import {
  API,
  APIGOOPEDIR,
  Busca,
  FormatoBR,
  Grava,
  Notificacao,
  PrimeiraLetraMaiuscula,
  Url,
  ValorProduto,
} from "../../controller/function";
import { useParams } from "react-router-dom";

import { Adicional } from "./adicional";
import { Sabor } from "./sabor";
import { CardFidelidade } from "./fidelidade";
import { ComponentLoading } from "../../componentes/loading";

export function PageProduto() {
  const [Empresa, setEmpresa] = useState([]);
  const [Categoria, setCategoria] = useState([]);
  const [DadosProduto, setDadosProduto] = useState([]);
  const [Adicionais, setAdicionais] = useState([]);
  const [Pizza, setPizza] = useState([]);

  const [CodigoProduto, setCodigoProduto] = useState(0);
  const { product } = useParams(); // Chama o hook aqui
  const { empresa } = useParams(); // Chama o hook aqui
  const [Quantidade, setQuantidade] = useState(1);

  const [AdicionaisSelecionados, setAdicionaisSelecionados] = useState([]);
  const [SaboresSelecionados, setSaboresSelecionados] = useState([]);

  const [TotalProduto, setTotalProduto] = useState(0);
  const [Observacao, setObservacao] = useState("");

  const adicionaisRefs = useRef({});

  function PegaCodigoProdutoUrl(codigo) {
    let produto = atob(codigo + "==");
    const parts = produto.split("&&-&&");
    return parts[2];
  }

  function Add() {
    setQuantidade(Quantidade + 1);
    CalculaTotal();
  }

  function Remove() {
    if (Quantidade > 1) {
      setQuantidade(Quantidade - 1);
    }
    CalculaTotal();
  }

  function TotalPizza() {
    var quantidade = 0;

    var tipoValor = 0;

    for (let chave in SaboresSelecionados) {
      quantidade += 1;
    }

    var soma = 0;
    for (let chave in SaboresSelecionados) {
      // Verifica se a chave começa com "sabor" (para garantir que seja um sabor)
      if (chave.startsWith("sabor")) {
        // Adiciona o valor de 'value' ao total de soma

        tipoValor = SaboresSelecionados[chave]?.tipo_valor;
        if (tipoValor == 0) {
          soma += SaboresSelecionados[chave]?.value;
        }
        if (tipoValor == 1) {
          soma += SaboresSelecionados[chave]?.value;
        }
        if (tipoValor == 2) {
          soma += SaboresSelecionados[chave]?.value;
        }
      }
    }
    if (tipoValor == 0) {
      soma = soma / quantidade;
    }
    if (soma > 0) {
      return soma;
    }
    return 0;
  }

  function CalculaTotal() {
    let tot = DadosProduto.preco_item;
    let adc = 0;

    var filtered = AdicionaisSelecionados.filter(function (item) {
      return item !== null && typeof item === "object";
    });

    for (var i = 0; i < filtered.length; i++) {
      adc = adc + filtered[i].total;
    }

    adc = adc + TotalPizza();
    tot = parseFloat(tot) + parseFloat(adc);

    setTotalProduto(tot * Quantidade);
  }

  useEffect(() => {
    if (Empresa.user_id) {
      const codigoProduto = PegaCodigoProdutoUrl(product); // Passe o valor para a função

      setCodigoProduto(codigoProduto);
      let test = [];

      APIGOOPEDIR.get(`api/produto/${codigoProduto}`).then(function (response) {
        setDadosProduto(response.data.dados[0]);
        if (
          parseInt(response.data.dados[0].user_id) == parseInt(Empresa.user_id)
        ) {
        } else {
          window.location.href = window.origin + "/" + empresa;
        }

        APIGOOPEDIR.get(`api/produto/adicional/${codigoProduto}`).then(
          function (response) {
            console.log(response.data.dados);
            if (response.data.dados != null) {
              for (var i = 0; i < response.data.dados.length; i++) {
                test[response.data.dados[i].id] = {
                  id: response.data.dados[i].id,
                  name: response.data.dados[i].name_adicionais_cat,
                  min: parseInt(response.data.dados[i].minimo),
                  max: parseInt(response.data.dados[i].amount),
                  selecionado: [],
                  qtd: 0,
                  total: 0,
                };
              }
              setAdicionais(response.data.dados);
            }
            console.log(test);
            setAdicionaisSelecionados(test);
          }
        );

        APIGOOPEDIR.get(`api/produto/pizza/${codigoProduto}`).then(function (
          response
        ) {
          setPizza(response.data);
        });
      });
    }
  }, [Empresa]);

  useEffect(() => {
    CalculaTotal();
  }, [DadosProduto, Quantidade]);

  function AddSabor(obj) {
    let adc = SaboresSelecionados["sabor" + obj.id];
    let Alterado = SaboresSelecionados;

    if (obj.status) {
      adc = obj;
    } else {
      adc = undefined;
    }

    Alterado["sabor" + obj.id] = adc;
    setSaboresSelecionados(Alterado);
    let meuid = obj.id + 2;
    const element = document.getElementById("sabor" + meuid);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      const observacao = document.getElementById("observacao");
      observacao.scrollIntoView({ behavior: "smooth" });
    }
    CalculaTotal();
  }

  function RemoveSabor(obj) {
    let adc = SaboresSelecionados["sabor" + obj.id];
    let Alterado = SaboresSelecionados;
    if (obj.status) {
      adc = obj;
    } else {
      adc = undefined;
    }

    Alterado["sabor" + obj.id] = adc;
    setSaboresSelecionados(Alterado);
    CalculaTotal();
  }

  function AddAdicional(obj) {
    let adc = AdicionaisSelecionados[obj.id_categoria];

    if (obj.status) {
      adc.selecionado[obj.id] = {
        name: obj.name,
        value: obj.value,
        qtd: 1,
        id: obj.id,
      };
      adc.total = parseFloat(adc.total) + parseFloat(obj.value);
      adc.qtd = parseInt(adc.qtd) + 1;
    } else {
      adc.selecionado[obj.id] = {};
      adc.total = parseFloat(adc.total) - parseFloat(obj.value);
      adc.qtd = parseInt(adc.qtd) - 1;
    }

    let Alterado = AdicionaisSelecionados;
    Alterado[obj.id_categoria] = adc;
    setAdicionaisSelecionados(Alterado);

    CalculaTotal();
  }

  function RetornaPizzas() {
    var objeto = { pizza: false, tipoValor: 0, valor: 0, sabores: [] };
    var qtdSelecionado = 0;
    var qtdSabores = 0;
    var tipoValor = 0;
    var valor = 0;

    for (let chave in SaboresSelecionados) {
      if (chave.startsWith("sabor")) {
        qtdSelecionado = +1;
        qtdSabores = SaboresSelecionados[chave].max;
        tipoValor = SaboresSelecionados[chave].tipo_valor;
        objeto.sabores.push({
          id: SaboresSelecionados[chave].id + 1,
          tipo: SaboresSelecionados[chave].tipo,
          nome: SaboresSelecionados[chave].name,
          value: SaboresSelecionados[chave].value,
          sabor: SaboresSelecionados[chave].sabor,
        });
        if (tipoValor == 0) {
          valor += SaboresSelecionados[chave].value / qtdSabores;
        }
        if (tipoValor == 1) {
          valor += SaboresSelecionados[chave].value;
        }
      }
    }

    objeto.pizza = qtdSabores == qtdSelecionado;
    objeto.quantidade = qtdSabores;
    objeto.tipoValor = tipoValor;
    objeto.valor = valor;

    return objeto;
  }

  function Adicionar() {
    var filtered = AdicionaisSelecionados.filter(function (item) {
      return item !== null && typeof item === "object";
    });

    for (var i = 0; i < filtered.length; i++) {
      let Selecionar = false;

      if (parseInt(filtered[i].min) > 0) {
        if (parseInt(filtered[i].min) > parseInt(filtered[i].qtd)) {
        } else if (parseInt(filtered[i].min) === parseInt(filtered[i].qtd)) {
          Selecionar = true;
        }
      } else {
        Selecionar = true;
      }

      if (!Selecionar) {
        Notificacao(`Deve-se selecionar ${filtered[i].name}`);

        var elemento = document.getElementById(`adicional${filtered[i].id}`);

        if (elemento) {
          // Rola suavemente para o elemento
          elemento.scrollIntoView({ behavior: "smooth", block: "start" });

          // Opcional: Ajustar o deslocamento para evitar sobreposição
          window.scrollBy(0, -20); // Ajuste o valor negativo conforme necessário
        }

        return false;
      }

      var filteredNew = filtered[i].selecionado.filter(function (item) {
        return item !== null && typeof item === "object";
      });

      filtered[i].selecionado = filteredNew;
    }
    let dadosPizza = RetornaPizzas();

    let bodyProduto = {
      produto: DadosProduto,
      adicional: filtered,
      pizza: dadosPizza,
      total: TotalProduto,
      quantidade: Quantidade,
      observacao: PrimeiraLetraMaiuscula(Observacao),
    };

    let memoria = JSON.parse(Busca("cart"));

    if (!memoria) {
      memoria = [];
    }

    memoria.push(bodyProduto);

    Grava("cart", JSON.stringify(memoria));

    let cliente = JSON.parse(Busca("client"));

    APIGOOPEDIR.post(`api/recovery/order`, memoria, {
      headers: {
        celular: cliente.celular,
        nome: cliente.nome,
        user: Empresa.user_id,
        site: Url(),
      },
    }).then(function (response) {
      window.location.href = Url();
    });

    //https://site-api-v2.goopedir.com/api/recovery/order

    //
  }

  return (
    <>
      <ComponenteEmpresa
        setempresa={setEmpresa}
        setcategoria={setCategoria}
        display={"none"}
      />
      {Empresa.length !== 0 ? (
        <>
          <body>
            <div className="bg-top details"></div>

            <header className="width-fix mt-3">
              <div className="card">
                <div className="d-flex">
                  <a href={Url()} className="container-voltar">
                    <i className="fas fa-arrow-left"></i>
                  </a>
                  <div className="infos text-center">
                    <h1 className="mb-0">
                      <b>Detalhes do produto</b>
                    </h1>
                  </div>
                </div>
              </div>
            </header>

            <section className="imagem width-fix mt-4">
              <div className="container-imagem-produto">
                <img
                  src={
                    DadosProduto.img_ifood != ""
                      ? `${DadosProduto.img_ifood}`
                      : `https://fotos.goopedir.com/fotos/${btoa(
                          DadosProduto.id
                        )}`
                  }
                  alt={DadosProduto.nome_item}
                ></img>
              </div>
              <div className="card mb-2">
                <div className="d-flex">
                  <div className="infos-produto">
                    <p className="name mb-2">
                      <b>{PrimeiraLetraMaiuscula(DadosProduto.nome_item)}</b>
                    </p>
                    <p className="description mb-4">
                      {PrimeiraLetraMaiuscula(DadosProduto.descricao_item)}
                    </p>
                    <p className="price">
                      {/* <b>{FormatoBR(DadosProduto.preco_item)}</b> */}
                      <b>{ValorProduto(DadosProduto)}</b>
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <CardFidelidade product={DadosProduto} />

            <section
              className="opcionais width-fix mt-5 pb-5"
              id={"adicionais"}
            >
              <Adicional
                Adicionais={Adicionais}
                AdicionaisSelecionados={AdicionaisSelecionados}
                AddAdicional={AddAdicional}
                produto={CodigoProduto}
              />
              {Pizza.length > 0 ? (
                <Sabor
                  Pizza={Pizza}
                  AdicionaisSelecionados={SaboresSelecionados}
                  AddAdicional={AddSabor}
                  RemoveSabor={RemoveSabor}
                />
              ) : null}

              <div className="container-group mb-5" id={"observacao"}>
                <p className="title-categoria mb-0">
                  <b>Observações</b>
                </p>
                <span className="sub-title-categoria">
                  Informe alguma observação abaixo
                </span>

                <textarea
                  className="form-control mt-2"
                  rows="5"
                  value={Observacao}
                  onChange={(e) => setObservacao(e.target.value)}
                ></textarea>
              </div>
            </section>

            <section className="menu-bottom details" id="menu-bottom">
              <div className="add-carrinho">
                <span className="btn-menos" onClick={Remove}>
                  <i className="fas fa-minus"></i>
                </span>
                <span className="add-numero-itens">{Quantidade}</span>
                <span className="btn-mais" onClick={Add}>
                  <i className="fas fa-plus"></i>
                </span>
              </div>
              <a className="btn btn-yellow btn-sm" onClick={Adicionar}>
                Adicionar <span>{FormatoBR(TotalProduto)}</span>
              </a>
            </section>

            <section
              className="menu-bottom disabled hidden"
              id="menu-bottom-closed"
            >
              <p className="mb-0">
                <b>Loja fechada no momento.</b>
              </p>
            </section>
          </body>
        </>
      ) : (
        <ComponentLoading />
      )}
    </>
  );
}
