import React, { useState, useRef, useEffect, CSSProperties } from "react";
import { ComponenteEmpresa } from "../../componentes/empresa";
import {
  APIGOOPEDIR,
  Busca,
  FocoComponente,
  FormatoBR,
  Grava,
  Notificacao,
  PrimeiraLetraMaiuscula,
  RetornaIconePagamento,
  Url,
  apenasNumeros,
} from "../../controller/function";
import { ComponenteCarrinhoProduto } from "./componente/produto";
import InputMask from "react-input-mask";
import { ShowModal } from "../../componentes/modal";

import { useParams } from "react-router-dom";
import { CarrinhoMapa } from "../../componentes/carrinho/mapa";

import Select from "react-select";
import { ComponentePagamentoCarrinho } from "../../componentes/carrinho/pagamento";
import { ComponenteFidelidade } from "../../componentes/fidelidade/carrinho";
import {
  RetornoProduto,
  ValidarCelular,
  ValidarCliente,
  ValidarEndereco,
  ValidarNome,
} from "./controller";
import { ModalCupom } from "./componente/cupom";
import { ComponentLoading } from "../../componentes/loading";
import { EnviaClienteRedis } from "../../utils/util";

export function PageCarrinho() {
  const { empresa } = useParams();

  const [Empresa, setEmpresa] = useState(null);
  const [Categoria, setCategoria] = useState([]);

  const [Carrinho, setCarrinho] = useState([]);
  const [Delivery, setDelivery] = useState(null);

  const [TotalPedido, setTotalPedido] = useState(0);
  const [TotalEntrega, setTotalEntrega] = useState(0);
  const [TotalTaxaPix, setTotalTaxaPix] = useState(0);

  const [TaxGoopedir, setTaxGoopedir] = useState(0);
  const [TaxMP, setTaxMP] = useState(0);

  const [LoadMapa, setLoadMapa] = useState(false);
  const [isEndereco, setIsEndereco] = useState(false);

  const [Bairros, setBairros] = useState([]);

  const [Pagamentos, setPagamentos] = useState([]);
  const [isPagamento, setIsPagamento] = useState(false);
  const [PagamentoSelecionado, setPagamentoSelecionado] = useState({});
  const [Troco, setTroco] = useState(0);

  const [CupomDesconto, setCupomDesconto] = useState("");
  const [isCupom, setIsCupom] = useState(false);
  const [DadosCupom, setDadosCupom] = useState({ status: false });

  const [ErroWarning, setErroWarning] = useState("");
  const minhaDivRef = useRef(null);

  const [Cliente, setCliente] = useState({
    celular: "",
    nome: "",
    cpf: "",
    nascimento: "",
    rua: "",
    ruaa: "",
    bairro: "",
    cidade: "",
    uf: "",
    complemento: "",
    numero: "",
    lat: 0,
    lng: 0,
  });

  const [opcaoSelecionada, setOpcaoSelecionada] = useState("");

  // Add new state for tracking selected products for points
  const [SelectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (Empresa != null) {
      setLoadMapa(true);

      APIGOOPEDIR.get(`api/forma/pagamento/${Empresa.user_id}`).then(function (
        response
      ) {
        let data = response.data;
        setPagamentos(data);
      });

      APIGOOPEDIR.get(`api/taxa/entrega/${Empresa.user_id}`).then(function (
        response
      ) {
        let data = response.data;

        const dataBairro = data.map((item) => ({
          bairro: item.bairro,
          label: `${PrimeiraLetraMaiuscula(
            item.bairro
          )}, ${PrimeiraLetraMaiuscula(item.cidade)}-${item.uf} (${FormatoBR(
            item.taxa
          )})`,
          value: item.id,
          cidade: item.cidade,
          uf: item.uf,
          taxa: item.taxa,
        }));

        setBairros(dataBairro);
      });
    }
  }, [Empresa]);

  function removerAcentos(str) {
    const mapaAcentos = {
      á: "a",
      à: "a",
      ã: "a",
      â: "a",
      é: "e",
      è: "e",
      ê: "e",
      í: "i",
      ì: "i",
      î: "i",
      ó: "o",
      ò: "o",
      õ: "o",
      ô: "o",
      ú: "u",
      ù: "u",
      û: "u",
      ç: "c",
    };
    try {
      return str
        .toLowerCase()
        .replace(
          /[áàãâéèêíìîóòõôúùûç]/g,
          (match) => mapaAcentos[match] || match
        );
    } catch (e) {}
  }

  const buscarBairro = (bairro) => {
    const bairroLowerCase = removerAcentos(bairro);

    const bairroEncontrado = Bairros.find(
      (item) => removerAcentos(item.bairro) === bairroLowerCase
    );

    if (bairroEncontrado?.taxa > 0) {
      setTotalEntrega(bairroEncontrado?.taxa);
    }

    setOpcaoSelecionada(bairroEncontrado || null);
    return bairroEncontrado || null;
  };

  useEffect(() => {
    let Cart = JSON.parse(Busca("cart"));

    if (!Cart) {
      Cart = [];
    }

    setCarrinho(Cart);

    let Client = JSON.parse(Busca("client"));

    if (Client) {
      setCliente(Client);
    }
  }, []);

  useEffect(() => {
    let element = null;
    if (PagamentoSelecionado?.troco) {
      element = document.getElementById("troco");
    } else {
      element = document.getElementById("closePagamento");
    }
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [PagamentoSelecionado]);

  useEffect(() => {
    Total();
  }, [PagamentoSelecionado, Carrinho, DadosCupom, TotalEntrega]);

  useEffect(() => {
    GravaCliente();
  }, [Cliente]);

  useEffect(() => {
    if (opcaoSelecionada?.bairro) {
      SetDadosCliente("bairro", opcaoSelecionada?.bairro);
      SetDadosCliente("cidade", opcaoSelecionada?.cidade);
      SetDadosCliente("uf", opcaoSelecionada?.uf);
    }
  }, [opcaoSelecionada]);

  useEffect(() => {
    buscarBairro(Cliente.bairro);
  }, [Delivery]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none", // Remove a borda
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#e2e6ea", // Cor de fundo quando selecionado
    }),
  };

  const handleSelecao = (selectedOption) => {
    setOpcaoSelecionada(selectedOption);
  };

  const customStrings = {
    allItemsAreSelected: "Todos os itens são selecionados.",
    clearSearch: "Limpar pesquisa",
    clearSelected: "Limpar selecionado",
    noOptions: "Sem opções",
    search: "Procurar",
    selectAll: "Selecionar tudo",
    selectAllFiltered: "Selecionar tudo (filtrado)",
    selectSomeItems: "Selecione...",
    create: "Criar",
  };

  function TotalProduto() {
    let tot = 0;

    for (let i = 0; i < Carrinho.length; i++) {
      tot = parseFloat(tot) + Carrinho[i].total;
    }

    return tot;
  }

  function TotalDesconto() {
    // let tot = TotalProduto() * (DadosCupom.tipo == 1 ? DadosCupom.)
    let tot = TotalProduto();

    if (DadosCupom?.status) {
      if (DadosCupom?.tipo == 1) {
        tot = (tot * DadosCupom?.valor) / 100;
      } else {
        tot = DadosCupom?.valor;
      }

      return tot;
    } else {
      return 0;
    }
  }

  function Total() {
    let tot = parseFloat(TotalEntrega) - parseFloat(TotalDesconto());
    let taxPix = 0;
    let taxGoopedir = 0;
    let taxMP = 0;

    tot += TotalProduto();

    if (PagamentoSelecionado.pix) {
      taxPix = (tot * (Empresa.tax_goopedir + Empresa.tax_mercado_pago)) / 100;
      taxGoopedir = (tot * Empresa.tax_goopedir) / 100;
      taxMP = (tot * Empresa.tax_mercado_pago) / 100;
      setTaxGoopedir(taxGoopedir);
      setTaxMP(taxMP);

      setTotalTaxaPix(taxPix);
    }

    setTotalPedido(tot + taxPix);
  }

  function SetDadosCliente(field, valor) {
    setCliente((prevCliente) => {
      let client = { ...prevCliente };
      client[field] = PrimeiraLetraMaiuscula(valor);
      return client;
    });
  }

  function GravaCliente() {
    Grava("client", JSON.stringify(Cliente));
  }

  function SetaCelular(celular) {
    SetDadosCliente("celular", apenasNumeros(celular));
    if (celular.length == 15) {
      let client = Cliente;
      client.celular = apenasNumeros(celular);

      // "generica.php?tabela=ws_pedidos&where=telefone = '" +
      // apenasNumeros(celular) +
      // "' and user_id = '" +
      // Empresa.user_id +
      // "' and opcao_delivery <> 'false' order by ws_pedidos.id desc limit 1"

      APIGOOPEDIR.get(
        `api/dados/cliente/${client.celular}/${Empresa.user_id}`
      ).then(function (response) {
        const data = response.data;
        // Verifique se os dados são válidos e são um array
        if (Array.isArray(data) && data.length) {
          const clientData = data[0];
          let client = Cliente;

          // Atributos que desejamos definir no cliente
          const attributes = [
            "nome",
            "cpf",
            "nascimento",
            "rua",
            "ruaa",
            "bairro",
            "cidade",
            "uf",
            "complemento",
            "numero",
            "lat",
            "lng",
          ];

          attributes.forEach((attr) => {
            if (clientData[attr]) {
              client[attr] = PrimeiraLetraMaiuscula(clientData[attr]);
            }
          });

          setCliente(client);
        }
      });
    }
  }

  function Remove(index) {
    const newArray = [...Carrinho]; // Crie uma cópia do array do estado
    if (index > -1 && index < newArray.length) {
      newArray.splice(index, 1);
      Grava("cart", JSON.stringify(newArray));

      let cliente = JSON.parse(Busca("client"));

      APIGOOPEDIR.post(`api/recovery/order`, newArray, {
        headers: {
          celular: cliente.celular,
          nome: cliente.nome,
          user: Empresa.user_id,
          site: Url(),
        },
      }).then(function (response) {
        setCarrinho(newArray);
      });

      // JSON.parse(Busca('cart'));
      // Atualize o estado com o novo array
    }
  }

  function infoEndereco() {
    setIsEndereco(true);
  }

  function ConcluirPedido() {
    let cliente = ValidarCliente(Empresa.fidelidade_status, Cliente);
    let endereco = ValidarEndereco(Delivery, Cliente);
    EnviaClienteRedis(empresa);

    let produtos = RetornoProduto(Carrinho);
    let pagamento = PagamentoSelecionado;
    let totalizador = {
      produtos: TotalProduto(),
      entrega: TotalEntrega,
      desconto: TotalDesconto(),
      total: TotalPedido,
      troco: Troco,
      mpValor: TaxMP,
      mpPercentual: Empresa.tax_mercado_pago,
      goopedirValor: TaxGoopedir,
      goopedirPercentual: Empresa.tax_goopedir,
    };

    let other = {
      url: Url(),
      partner: "site",
      cupom: CupomDesconto,
    };

    if (!cliente.status) {
      Notificacao(cliente.mensagem);
      return false;
    }

    if (!endereco.status) {
      Notificacao(endereco.mensagem);
      return false;
    }

    let body = {
      cliente: cliente.cliente,
      endereco: endereco.endereco,
      pagamento: pagamento,
      totalizador: totalizador,
      produtos: produtos,
      empresa: Empresa,
      site: true,
      other: other,
    };

    APIGOOPEDIR.post("/api/pedido", body)
      .then(function (response) {
        let error = response.data.erro;

        if (error) {
          setErroWarning(error.description);
          Notificacao("Confira as pendências!");
          console.error(minhaDivRef);
          minhaDivRef.current?.scrollIntoView({ behavior: "smooth" });
        } else {
          window.location.href = response.data.url;
        }
      })
      .catch(function (response) {
        let error = response.response.data.erro;

        Notificacao(error.description);
      });
  }

  // Add function to handle product selection for points
  function handleProductSelection(index, product) {
    setSelectedProducts((prevSelected) => {
      const newSelected = [...prevSelected];

      // Check if product is already selected
      const existingIndex = newSelected.findIndex(
        (item) => item.index === index
      );

      if (existingIndex >= 0) {
        // Remove if already selected
        newSelected.splice(existingIndex, 1);
      } else {
        // Add new selection with points calculation
        newSelected.push({
          index: index,
          points: (product.pontos || 0) * product.quantidade,
        });
      }

      return newSelected;
    });
  }

  return (
    <>
      <ComponenteEmpresa
        setempresa={setEmpresa}
        setcategoria={setCategoria}
        display={"none"}
      />

      {Empresa ? (
        <>
          {isCupom ? (
            <ModalCupom
              CloseButton={() => setIsCupom(false)}
              CupomDesconto={CupomDesconto}
              setCupomDesconto={setCupomDesconto}
              Empresa={Empresa}
              setDadosCupom={setDadosCupom}
            />
          ) : null}
          {isEndereco ? (
            <>
              <ShowModal>
                <header className="width-fix mt-3">
                  <div className="card">
                    <div className="d-flex">
                      <div
                        className="container-voltar"
                        onClick={() => setIsEndereco(false)}
                      >
                        <i className="fas fa-arrow-left"></i>
                      </div>
                      <div className="infos text-center">
                        <h1 className="mb-0">
                          <b>Dados Entrega</b>
                        </h1>
                      </div>
                    </div>
                  </div>
                </header>
                <section className="opcionais width-fix mt-5 pb-5">
                  {/* <div className="container-group mb-2">
                    <div>
                      <p className="title-categoria mb-0">
                        <b>Mapa</b>
                      </p>
                      <p className="title-categoria mb-0">
                        Utilize o mapa para encontrar seu endereço, vale
                        resaltar que ele não é 100% preciso, verifique sempre o
                        nome da sua rua e o número da sua casa!
                      </p>
                    </div>
                  </div>
                  {LoadMapa && (
                    <CarrinhoMapa
                      Empresa={Empresa}
                      Set={SetDadosCliente}
                      Busca={buscarBairro}
                    />
                  )} */}
                  <div className="container-group mt-2">
                    <p className="title-categoria mb-0">
                      <b>Rua</b>
                      <span className="badge">Obrigatório</span>
                    </p>

                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="* Informe nome da sua rua"
                      value={Cliente.rua}
                      onChange={(e) => SetDadosCliente("rua", e.target.value)}
                    />
                  </div>
                  <div className="container-group mt-2">
                    <p className="title-categoria mb-0">
                      <b>Número</b>
                      <span className="badge">Obrigatório</span>
                    </p>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="* Informe o número"
                      value={Cliente.numero}
                      onChange={(e) =>
                        SetDadosCliente("numero", e.target.value)
                      }
                    />
                  </div>
                  <div className="container-group mt-2">
                    <p className="title-categoria mb-0">
                      <b>Complemento</b>
                      <span className="badge">Obrigatório</span>
                    </p>
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="* Informe o complemento"
                      value={Cliente.complemento}
                      onChange={(e) =>
                        SetDadosCliente("complemento", e.target.value)
                      }
                    />
                  </div>

                  <div className="container-group mt-2">
                    <p className="title-categoria mb-3">
                      <b>Bairro</b>
                      <span className="badge">Obrigatório</span>
                    </p>
                    <Select
                      value={opcaoSelecionada}
                      onChange={handleSelecao}
                      options={Bairros}
                      placeholder="Selecione uma opção"
                    />
                  </div>
                  <div className="container-group mt-5">
                    <div
                      className="btn btn-yellow btn-full-size"
                      onClick={() => setIsEndereco(false)}
                    >
                      Salvar
                    </div>
                  </div>
                </section>
              </ShowModal>
            </>
          ) : null}

          <body>
            <div className="bg-top pedido"></div>

            <header className="width-fix mt-3">
              <div className="card">
                <div className="d-flex">
                  <a href="./" className="container-voltar">
                    <i className="fas fa-arrow-left"></i>
                  </a>
                  <div className="infos text-center">
                    <h1 className="mb-0">
                      <b>Seu carrinho</b>
                    </h1>
                  </div>
                </div>
              </div>
            </header>

            {Carrinho.length == 0 ? (
              <>
                <section class="carrinho width-fix mt-4">
                  <div class="card card-address">
                    <div class="img-icon-details">
                      <i class="fas fa-cart-plus"></i>
                    </div>
                    <div class="infos">
                      <p class="name mb-0">
                        <b>Seu carrinho está vazio</b>
                      </p>
                      <span class="text mb-0">
                        Volte ao cardápio, selecione os itens e adicione ao seu
                        carrinho.
                      </span>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <>
                <section className="carrinho width-fix mt-4">
                  {Carrinho.map((produto, index) => (
                    <>
                      <ComponenteCarrinhoProduto
                        key={index}
                        product={produto}
                        remove={Remove}
                        index={index}
                        isSelected={SelectedProducts.some(
                          (item) => item.index === index
                        )}
                        onSelect={() => handleProductSelection(index, produto)}
                      />
                    </>
                  ))}

                  <div className="card mb-2">
                    <div className="detalhes-produto">
                      <div className="infos-produto">
                        <p className="name mb-0">
                          <i className="fas fa-bacon"></i>&nbsp; <b>Produtos</b>
                        </p>
                        <p className="price mb-0">
                          <b>{FormatoBR(TotalProduto())}</b>
                        </p>
                      </div>
                    </div>
                  </div>

                  {DadosCupom?.status ? (
                    <>
                      <div className="card mb-2">
                        <div className="detalhes-produto">
                          <div className="infos-produto">
                            <p className="name mb-0">
                              <i className="fas fa-gift"></i>&nbsp;{" "}
                              <b>Desconto</b>
                            </p>
                            <p className="price mb-0">
                              <b>- {FormatoBR(TotalDesconto())}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {TotalEntrega > 0 ? (
                    <>
                      <div className="card mb-2">
                        <div className="detalhes-produto">
                          <div className="infos-produto">
                            <p className="name mb-0">
                              <i className="fas fa-motorcycle"></i>&nbsp;{" "}
                              <b>Taxa de entrega</b>
                            </p>
                            <p className="price mb-0">
                              <b>+ {FormatoBR(TotalEntrega)}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {PagamentoSelecionado.pix ? (
                    <>
                      <div className="card mb-2">
                        <div className="detalhes-produto">
                          <div className="infos-produto">
                            <p className="name mb-0">
                              {RetornaIconePagamento(
                                PagamentoSelecionado.descricao
                              )}
                              &nbsp; <b>Taxa Serviço</b>
                            </p>
                            <p className="price mb-0">
                              <b>+ {FormatoBR(TotalTaxaPix)}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="card mb-2" onClick={() => setIsCupom(true)}>
                    <div
                      className="detalhes-produto"
                      onClick={() => setIsCupom(true)}
                    >
                      <div
                        className="infos-produto"
                        onClick={() => setIsCupom(true)}
                      >
                        <p
                          className="name mb-0"
                          onClick={() => setIsCupom(true)}
                        >
                          <i
                            className="fas fa-percent"
                            onClick={() => setIsCupom(true)}
                          ></i>
                          &nbsp;{" "}
                          <b onClick={() => setIsCupom(true)}>
                            {DadosCupom.status
                              ? DadosCupom.cupom.toUpperCase()
                              : "Cupom"}
                          </b>
                        </p>
                      </div>
                      <p
                        style={{ fontSize: "0.75rem", height: "0px" }}
                        onClick={() => setIsCupom(true)}
                      >
                        Clique aqui para informar o cupom
                      </p>
                    </div>
                  </div>

                  <div className="card mb-2">
                    <div className="detalhes-produto">
                      <div className="infos-produto">
                        <p className="name-total mb-0">
                          <b>Total</b>
                        </p>
                        <p className="price-total mb-0">
                          <b>{FormatoBR(TotalPedido)}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="opcionais width-fix mt-5 pb-5">
                  <div className="container-group mb-5">
                    <span className="badge">Obrigatório</span>

                    <p className="title-categoria mb-0">
                      <b>Escolha uma opção</b>
                    </p>
                    <span className="sub-title-categoria">
                      Como quer receber o pedido?
                    </span>

                    <div className="card card-opcionais mt-2">
                      <div className="infos-produto-opcional">
                        <div>
                          <p className="name mb-0">
                            <b>Entrega</b>
                          </p>
                          <p>
                            <p className="title-categoria mb-0">
                              {Empresa.msg_tempo_delivery}
                            </p>
                          </p>
                        </div>
                      </div>
                      <div
                        className="checks"
                        onClick={() => [
                          setDelivery(true),
                          FocoComponente("endereco"),
                        ]}
                      >
                        <label className="container-check">
                          <input type="checkbox" checked={Delivery} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div className="card card-opcionais mt-2">
                      <div className="infos-produto-opcional">
                        <div>
                          <p className="name mb-0">
                            <b>Retirar no Estabelecimento</b>
                          </p>
                          <p>
                            <p className="title-categoria mb-0">
                              {Empresa.msg_tempo_buscar}
                            </p>
                          </p>
                        </div>
                      </div>
                      <div
                        className="checks"
                        onClick={() => setDelivery(false)}
                      >
                        <label className="container-check">
                          <input type="checkbox" checked={!Delivery} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="container-group mb-2">
                    <span className="badge">Obrigatório</span>

                    <p className="title-categoria mb-0">
                      <b>Número do seu celular</b>
                    </p>
                    <span className="sub-title-categoria">
                      Para mais informações do pedido
                    </span>

                    <InputMask
                      mask="(99) 99999-9999"
                      maskChar={null}
                      placeholder="(00) 00000-0000"
                      className="form-control mt-2"
                      onChange={(e) => SetaCelular(e.target.value)}
                      value={Cliente.celular}
                    >
                      {(inputProps) => <input {...inputProps} />}
                    </InputMask>
                  </div>

                  <ComponenteFidelidade
                    Empresa={Empresa}
                    Cliente={Cliente}
                    SetDadosCliente={SetDadosCliente}
                  />

                  <div className="container-group mb-5">
                    <span className="badge">Obrigatório</span>

                    <p className="title-categoria mb-0">
                      <b>Nome e Sobrenome</b>
                    </p>
                    <span className="sub-title-categoria">
                      Como vamos te chamar?
                    </span>

                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="* Informe o nome e sobrenome"
                      value={Cliente.nome}
                      onChange={(e) => SetDadosCliente("nome", e.target.value)}
                    />
                  </div>

                  {Delivery ? (
                    <>
                      <div className="container-group mb-5" id="endereco">
                        <span className="badge">Obrigatório</span>

                        <p className="title-categoria mb-0">
                          <b>Qual o seu endereço?</b>
                        </p>
                        <span className="sub-title-categoria">
                          Informe o endereço da entrega
                        </span>

                        {Cliente?.bairro == "" ? (
                          <>
                            <div
                              className="card card-select mt-2"
                              onClick={infoEndereco}
                            >
                              <div
                                className="infos-produto-opcional"
                                onClick={() => setIsEndereco(true)}
                              >
                                <p className="mb-0 color-primary">
                                  <i className="fas fa-plus-circle"></i>&nbsp;
                                  Nenhum endereço selecionado
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="card card-address mt-2">
                              <div className="img-icon-details">
                                <i className="fas fa-map-marked-alt"></i>
                              </div>
                              <div className="infos">
                                <p className="name mb-0">
                                  <b>
                                    {Cliente.rua}, {Cliente.numero},{" "}
                                    {Cliente.bairro}
                                  </b>
                                </p>
                                <span className="text mb-0">
                                  {Cliente.cidade}-{Cliente.uf}
                                </span>
                              </div>
                              <div className="icon-edit">
                                <i
                                  className="fas fa-pencil-alt"
                                  onClick={infoEndereco}
                                ></i>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : null}

                  <div className="container-group mb-5">
                    <span className="badge">Obrigatório</span>

                    <p className="title-categoria mb-0">
                      <b>Como você prefere pagar?</b>
                    </p>
                    <span className="sub-title-categoria">
                      * Pagamento na entrega / online
                    </span>

                    {Object.keys(PagamentoSelecionado).length === 0 ? (
                      <div
                        className="card card-select mt-2"
                        onClick={() => setIsPagamento(true)}
                      >
                        <div className="infos-produto-opcional">
                          <p className="mb-0 color-primary">
                            <i className="fas fa-plus-circle"></i>&nbsp; Nenhuma
                            forma selecionada
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="card card-address mt-2">
                        <div className="img-icon-details">
                          {PagamentoSelecionado.descricao
                            ? RetornaIconePagamento(
                                PagamentoSelecionado.descricao
                              )
                            : null}
                          {/* <i className="fas fa-map-marked-alt"></i> */}
                        </div>
                        <div className="infos">
                          <p className="name mb-0">
                            <b>
                              {PrimeiraLetraMaiuscula(
                                PagamentoSelecionado.descricao
                              )}
                            </b>
                          </p>
                        </div>
                        <div
                          className="icon-edit"
                          onClick={() => setIsPagamento(true)}
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </div>
                      </div>
                    )}

                    {isPagamento && (
                      <ComponentePagamentoCarrinho
                        CloseButton={() => setIsPagamento(false)}
                        Data={Pagamentos}
                        setPagamentoSelecionado={setPagamentoSelecionado}
                        PagamentoSelecionado={PagamentoSelecionado}
                        Troco={Troco}
                        setTroco={setTroco}
                        Total={TotalPedido}
                      />
                    )}
                  </div>
                  {ErroWarning ? (
                    <>
                      <div
                        className="card mb-5"
                        id="pendencia"
                        ref={minhaDivRef}
                      >
                        <div className="detalhes-produto">
                          <div className="infos-produto">
                            <p className="name mb-0">
                              <i className="fas fa-bell"></i>&nbsp;{" "}
                              <b>Pendências</b>
                            </p>
                            <p className="price mb-0">{ErroWarning}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </section>

                <button
                  onClick={ConcluirPedido}
                  className="btn btn-yellow btn-full"
                >
                  Fazer pedido <span>{FormatoBR(TotalPedido)}</span>
                </button>

                {/* <section
                  className="menu-bottom disabled"
                  id="menu-bottom-closed"
                >
                  <p className="mb-0">
                    <b>Loja fechada no momento.</b>
                  </p>
                </section> */}
              </>
            )}
          </body>
        </>
      ) : (
        <ComponentLoading />
      )}
    </>
  );
}
